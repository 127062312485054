<template>
  <q-dialog
    ref="dialogRef"
    backdrop-filter="blur(1rem)"
    square
    persistent
  >
    <q-card flat>
      <q-card-section>
        <div class="flex flex-center q-mb-sm">
          <img
            src="/logos/kygunco.svg"
            width="300"
            height="60"
            style="max-width: 100%;"
          >
        </div>

        <p class="text-subtitle1 text-center">
          Welcome to KYGUNCO! Our site is intended for individuals of
          at least {{ age }} years of age.
        </p>

        <p class="text-h4 text-center text-weight-bold">
          Are you at least {{ age }} years old?
        </p>

        <div class="row justify-around q-gutter-md">
          <q-btn
            color="positive"
            label="Yes"
            size="lg"
            style="width: 125px"
            square
            @click="confirm"
          />
          <q-btn
            href="https://www.google.com"
            color="primary"
            label="No"
            size="lg"
            style="width: 125px"
            square
          />
        </div>

        <div class="flex flex-center q-pt-md">
          <q-checkbox
            v-model="remember"
            label="Remember me for 30 days. I confirm that this is not a shared device."
            size="sm"
          />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { useDialogPluginComponent } from 'quasar';

const props = defineProps({
  age: {
    type: Number,
    required: false,
    default: 18,
  },
});

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogOK } = useDialogPluginComponent();

const ageStore = useAgeStore();

const remember = ref(true);

const confirm = () => {
  if (remember.value) {
    ageStore.confirm(props.age);
  }

  onDialogOK();
};
</script>
